<template>
  <div id="personSelect">
    <div class="drop-header ant-round-form">
      <a-input
        v-model:value="inputValue"
        allow-clear
        size="large"
        style="border: 1px solid #F8F9FB"
        :placeholder="$t('pages_pay_010')"
        @change="e => inputChange(e.target.value)"
      >
        <template #prefix>
          <search-outlined />
        </template>
      </a-input>
      <a-button
        shape="round"
        type="primary"
        class="manage-btn"
        @click="$router.push({ path: dropType === 'person' ? '/funds/payee_manage' : '/funds/payer_manage', query: { key: methodType } })"
      >{{ dropType === 'person' ? $t('pages_pay_011') : $t('pages_pay_012') }}</a-button>
    </div>
    <div class="drop-menu" :style="{width: menuLength}">
      <div
        v-for="item in dropDataList"
        :key="item.code"
        :class="item.code === selectKey ? 'item-selected': 'item-select'"
        @click="menuSelect(item.code)"
      >
        <div class="bank-item flex-align">
          <img src="~@/assets/icons/ic-person.png">
          <div class="flex-align">
            <span style="margin-left: 8px">{{ item.name }}</span>
            <a-tooltip v-if="dropType === 'person'" placement="top" :title="`${item.currency}·${item.branchName}·${item.bankNum}`">
              <span style="margin-left: 12px">{{ item.currency }}·{{ item.branchName }}·{{ item.bankNum }}</span>
            </a-tooltip>
            <span v-else style="margin-left: 12px">{{ item.bankName }}·{{ item.bankNum }}</span>
          </div>
        </div>
      </div>
      <p v-if="dropDataList.length === 0" class="flex-align empty-content">{{ $t('common_text_052') }}</p>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
export default {
  name: 'PersonSelect',
  props: {
    menuLength: {
      type: String,
      default () {
        return '100%'
      }
    },
    dropType: {
      type: String,
      default () {
        return 'person'
      }
    },
    methodType: {
      type: String,
      default () {
        return 'inbound'
      }
    }
  },
  setup (props, ctx) {
    const state = reactive({
      selectKey: '',
      inputValue: '',
      dropData: [],
      dropDataList: []
    })

    const initData = (selectKey, dropDataList) => {
      if (selectKey) state.selectKey = selectKey
      state.dropData = dropDataList
      state.dropDataList = dropDataList
    }

    const menuSelect = (key) => {
      state.selectKey = key
      ctx.emit('select', key)
    }

    const inputChange = (e) => {
      if (e) {
        state.dropDataList = state.dropData.filter(item => {
          if (item.code.toLowerCase().indexOf(e.toLowerCase()) !== -1) return true
          if (item.name.toLowerCase().indexOf(e.toLowerCase()) !== -1) return true
          return false
        })
      } else {
        state.dropDataList = state.dropData
      }
    }

    return {
      menuSelect,
      inputChange,
      initData,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
#personSelect {
  padding: 15px 0;
  border: 1px solid @table-th-color;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
  .ant-menu-vertical {
    border: 0;
  }
  .drop-header {
    display: flex;
    align-items: center;
    padding: 0 12px;
    .manage-btn {
      margin-left: 24px;
    }
  }
  .ivu-input {
    height: 32px !important;
    padding-left: 28px;
  }
  .drop-menu {
    height: 180px;
    overflow-y: scroll;
    padding: 6px 12px 0 12px;
    .item-select, .item-selected {
      padding: 6px 12px;
      margin-bottom: 8px;
      cursor: pointer;
    }
    .item-selected {
      background: @table-th-color !important;
      border-radius: 50px !important;
    }
    .bank-item {
      color: #333;
      .flex-align {
        width: 100%;
        white-space: nowrap;
        .ivu-tooltip {
          overflow: hidden;
          .ivu-tooltip-rel {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      img {
        width: 20px;
        height: 25px;
      }
    }
  }
  .empty-content {
    height: 100%;
    justify-content: center;
    color: #bfbfbf;
  }
}
</style>

